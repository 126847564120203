import Swiper, { Pagination } from 'swiper';
Swiper.use([Pagination]);

let passCarrousel;

window.addEventListener('load', function () {
	const textSlide = [];
	const carrouselPassId = document.getElementById('carrousel-pass');
	const countSlider = document.querySelectorAll('#carrousel-pass .swiper-slide');
	const breakpoint = window.matchMedia('(min-width:1024px)');

	function breakpointChecker() {
		if (breakpoint.matches === true) {
			if (passCarrousel !== undefined && document.body.contains(carrouselPassId)) {
				passCarrousel.destroy(true, true);
			}
		} else {
			return enableSwiper();
		}
	}

	for (let i = 0; i < countSlider.length; ++i) {
		const text = countSlider[i].getAttribute('data-title');

		textSlide.push(text);
	}

	function enableSwiper() {
		passCarrousel = new Swiper(carrouselPassId, {
			slidesPerView: 1,
			spaceBetween: 10,
			watchOverflow: true,
			pagination: {
				el: '.swiper-pagination',
				clickable: true,
				renderBullet: function (index, className) {
					return '<span class="' + className + '">' + textSlide[index] + '</span>';
				},
			},
			breakpoints: {
				576: {
					slidesPerView: 'auto',
					centeredSlides: true,
					spaceBetween: 20,
				},
			},
			on: {
				resize: function () {
					if (breakpoint.matches === true) {
						passCarrousel.destroy(true, true);
					}
				},
			},
		});
	}

	breakpoint.addListener(breakpointChecker);
	breakpointChecker();
});
